var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SidebarLeft',{class:{'sidebar-left-show': _vm.sidebars.leftSidebar}},[(_vm.isLeftSidebarVisible)?_c('PeopleAndCompaniesForm',{ref:"filter",on:{"update-role":function($event){_vm.selectedItem.name = $event}}}):_vm._e()],1),(['peoples', 'peoples-id'].includes(_vm.$route.name))?_c('TableContainer',{ref:"table-container-peoples",attrs:{"columns":_vm.tableDataPeoples,"title-add-button":"Контакт","counter-filter":_vm.$store.state.peoples.searchCount,"is-archive":_vm.$can('edit', 'human-read-archive'),"is-archiving":_vm.$can('edit', 'human-trash'),"is-loading-items":_vm.$store.state.other.isLoadingItems,"is-loading-new-item":_vm.$store.state.other.isLoadingNewItem,"is-remove-from-archive":_vm.$can('edit', 'human-delete'),"rows":_vm.$store.state.peoples.peoples,"show-add-button":_vm.$can('edit', 'human-create'),"sorting-fields":_vm.sortingFieldsPeoples,"is-archive":"","name":"peoples","title":"Контрагенты | Контакты"},on:{"show-right-sidebar":_vm.showRightSidebar,"on-selected":_vm.selectItem,"update-data":_vm.updateData,"archive-items":function($event){return _vm.archiveItems('peoples')},"delete-item":function($event){return _vm.deleteItem('peoples')},"return-from-archive":function($event){return _vm.returnFromArchive('peoples')},"next-page":_vm.loadNewData,"on-change-archive":() => {
        _vm.$store.commit('peoples/changePage', 1)
        if (_vm.$route.name !== 'peoples') _vm.$router.push({name: 'peoples'})
      }}}):(['organizations', 'organizations-id'].includes(_vm.$route.name))?_c('TableContainer',{ref:"table-container-companies",attrs:{"columns":_vm.tableDataCompanies,"title-add-button":"Организация","counter-filter":_vm.$store.state.companies.searchCount,"is-archive":_vm.$can('edit', 'organization-read-archive'),"is-archiving":_vm.$can('edit', 'organization-trash'),"is-loading-items":_vm.$store.state.other.isLoadingItems,"is-loading-new-item":_vm.$store.state.other.isLoadingNewItem,"is-remove-from-archive":_vm.$can('edit', 'organization-delete'),"rows":_vm.$store.state.companies.companies,"show-add-button":_vm.$can('edit', 'organization-create'),"sorting-fields":_vm.sortingFieldsCompanies,"is-archive":"","name":"companies","title":"Контрагенты | Организации"},on:{"show-right-sidebar":_vm.showRightSidebar,"on-selected":_vm.selectItem,"update-data":_vm.updateData,"delete-item":function($event){return _vm.deleteItem('companies')},"archive-items":function($event){return _vm.archiveItems('companies')},"return-from-archive":function($event){return _vm.returnFromArchive('companies')},"next-page":_vm.loadNewData,"on-change-archive":() => {
        _vm.$store.commit('companies/changePage', 1)
        if (_vm.$route.name !== 'companies') _vm.$router.push({name: 'companies'})
      }}}):_vm._e(),_c('SidebarRight',{class:{'sidebar-right--show': _vm.sidebars.rightSidebar}},[(_vm.$route.params.id && _vm.selectedRowsCompanies.length < 2 && _vm.selectedRowsPeoples.length < 2)?[(_vm.isPeoples)?_c('DetailsPeople',{ref:"people"}):_c('DetailsCompanies',{ref:"company"})]:_vm._e(),(_vm.newItem)?_c('div',{staticClass:"sidebar__container"},[_c('NewPeopleOrCompany',{attrs:{"type":_vm.selectedItem.name},on:{"create-item":_vm.showRightSidebar}})],1):_vm._e(),(_vm.selectedRowsPeoples.length > 1 && _vm.isPeoples)?_c('DetailsFilter',{attrs:{"count":_vm.selectedRowsPeoples.length,"fields":_vm.fieldsPeoples}}):_vm._e(),(_vm.selectedRowsCompanies.length > 1 && !_vm.isPeoples)?_c('DetailsFilter',{attrs:{"count":_vm.selectedRowsCompanies.length,"fields":_vm.fieldsCompanies}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }